
const Home = {
  text: 'Inicio',
  link: '/home',
  icon: 'icon-home'
};

const Administration = {
  text: 'Administración',
  link: '/management',
  icon: 'icon-notebook',
  submenu: [
    {
      text: 'Organización',
      link: '/management/levels',
      auth: 'ADMORG',
    },
    {
      text: 'Remisiones',
      link: '/management/remission-status',
      auth: 'REMSTA',
    },
    {
      text: 'Carta Porte',
      link: '/management/document-list',
      auth: 'ADMDOC',
    },
    {
      text: 'Misceláneos',
      link: '/miscellaneous/miscellaneous-list',
      auth: 'MISC',
    },
    {
      text: 'Etiquetas',
      link: '/management/label-list',
      auth: 'ADMETI',
    },
    {
      text: 'Autorregulación',
      link: '/management/self-regulation-list',
      auth: 'ADMAUT',
    }
  ]
}

const Inventory = {
  text: 'Inventarios',
  link: '/inventory',
  icon: 'icon-social-dropbox',
  submenu: [
    {
      text: 'Consulta de Inventario',
      link: '/inventory/notice',
      auth: 'ADMINV',
    },
    {
      text: 'Consulta de Inventario',
      link: '/inventory/noticemld',
      auth: 'ADMINVMLD',
    },
    {
      text: 'Consulta de Flujos',
      link: '/inventory/balance',
      auth: 'ADMSAL',
    },
    {
      text: 'Consulta de Flujos',
      link: '/inventory/mldbalance',
      auth: 'ADMSALMLD',
    },
    {
      text: 'Ajuste de Flujos',
      link: '/inventory/balance-adjust',
      auth: 'ADMSALAJUS',
    },
    {
      text: 'Movimientos ESAB',
      link: '/inventory/esab-movements',
      auth: 'ESABMOVS',
    },
    {
      text: 'Carátula Centro de Ventas',
      link: '/inventory/inventory-cut/inventory-ceve',
      auth: 'ADMINVCEVE',
    },
    {
      text: 'Carátula Plantas',
      link: '/inventory/inventory-cut/inventory-factory',
      auth: 'ADMINVFAB',
    },
    {
      text: 'Carátula Plantas',
      link: '/inventory/inventory-cut/mld-inventory-factory',
      auth: 'ADMINVFABM',
    },
    {
      text: 'Traspasos Automáticos',
      link: '/inventory/automatictransfer-cat',
      auth: 'TRAAUT',
    }
  ]
}


const Incidence = {
  text: 'Incidencias',
  link: '/incidence',
  icon: 'icon-exclamation',
  submenu: [
    {
      text: 'Gestión Mld',
      link: '/incidence/validate-incidence-mld-list',
      auth: 'INCCTRLMLD',
    },
    {
      text: 'Faltantes y Sobrantes',
      link: '/incidence/validate-incidence-list',
      auth: 'INCCTRL',
    },
    {
      text: 'Rechazos y Bajas',
      link: '/incidence/incidencerl-list',
      auth: 'INCREBA',
    },
    {
      text: 'Solicitar Autorización DC',
      link: '/incidence/inc-pt-ceve/0',
      auth: 'INCPTCEVE',
    },
    {
      text: 'Bitácora Autorizaciones',
      link: '/incidence/inc-pt-fab',
      auth: 'INCPTFAB',
    },
    {
      text: 'Bitácora de Servicios',
      link: '/incidence/ceve-record-list',
      auth: 'BITENVREC',
    }
  ]
}

const Autoservicios = {
  text: 'Multicanal',
  link: '/self-service',
  icon: 'fa fa-truck',
  submenu: [
    {
      text: 'Saldo de Clientes',
      link: 'self-service/customer-balance',
      auth: 'AUTCLI',
    },
    {
      text: 'Envío de Saldos QP',
      link: 'self-service/shipping-quick-pick',
      auth: 'AUTSEND',
    },
    {
      text: 'Plan B de Cargos',
      link: 'self-service/plan-b',
      auth: 'CARGAOLA',
    },
    {
      text: 'Comodato',
      link: 'self-service/equipment-loan/list',
      auth: 'LOANLIST',
    },

    {
      text: 'Mapa de Rutas',
      link: 'map/map-routes',
      auth: 'ROUTEMAP',
    }
  ]
}

var Entradas = {
  text: 'Alta de Equipo',
  link: '/recovery',
  icon: 'fa fa-shopping-basket',
  submenu: [
    {
      text: 'Planeación de Compra',
      link: '/purchase/planning',
      auth: 'COMREG'
    },
    {
      text: 'Recuperación de Seguridad',
      link: '/equipment-io/recoverylist/RECSEG',
      auth: 'RECSEG',
    }
  ]
};


var Salidas = {
  text: 'Baja de Equipo',
  link: '/recovery',
  icon: 'fa fa-industry',
  submenu: [
    {
      text: 'Cobro a Colaborador',
      link: '/equipment-io/recoverylist/COBCOL',
      auth: 'COBEQUI',
    },
    {
      text: 'Perdida Fortuita',
      link: '/equipment-io/recoverylist/PERFOR',
      auth: 'PERFOR',
    },
    {
      text: 'Otras Bajas',
      link: '/equipment-io/recoverylist/OTRBAJ',
      auth: 'OTRBAJA',
    },
    {
      text: 'Bajas por Manejo',
      link: '/equipment-io/recoverylist/BAJMAN',
      auth: 'BAJMAN',
    },
  ]
};


const Reports = {
  text: 'Reportes',
  link: '/reports',
  icon: 'icon-docs',
  submenu: [
    {
      text: 'Reporte de Altas y Bajas',
      link: '/reports/equipmentio',
      auth: 'REPAB',
    },
    {
      text: 'Reporte Cambio Equipo',
      link: '/reports/rep-container-exchange',
      auth: 'REPFLOWADJ',
    },
    {
      text: 'Reporte de Carátulas',
      link: '/reports/rep-cut',
      auth: 'REPCARAT',
    },
    {
      text: 'Reporte de Comodatos',
      link: '/reports/rep-equipmentloan',
      auth: 'REPLOAN',
    },
    {
      text: 'Reporte de Compulsas',
      link: '/reports/rep-adjust',
      auth: 'REPADJ',
    },
    {
      text: 'Reporte de Etiquetas',
      link: '/reports/rep-label',
      auth: 'REPLBL',
    },
    {
      text: 'Reporte de Flujos',
      link: '/reports/rep-balance',
      auth: 'REPBALA',
    },
    {
      text: 'Reporte de Incidencias',
      link: '/reports/rep-incidence',
      auth: 'REPINCI',
    },
    {
      text: 'Reporte de Inventarios',
      link: '/reports/rep-inventory',
      auth: 'REPINVE',
    },
    {
      text: 'Reporte de Movimientos',
      link: '/reports/rep-movement',
      auth: 'REPMOVE',
    },
    {
      text: 'Reporte de Tránsito',
      link: '/reports/rep-ongoing',
      auth: 'REPTRAN',
    }
  ]
};

const Security = {
  text: 'Seguridad',
  link: '/security',
  icon: 'icon-lock',
  submenu: [
    {
      text: 'Actividades',
      link: '/security/activity',
      auth: 'SEGACT',
    },
    {
      text: 'Roles',
      link: '/security/role',
      auth: 'SEGROL',
    },
    {
      text: 'Usuarios',
      link: '/security/user',
      auth: 'SEGUSU',
    },
    {
      text: 'Pre Usuarios',
      link: '/security/pre-user',
      auth: 'OPESEG',
    }
  ]
}

const Catalog = {
  text: 'Catálogo Organización',
  link: '/organization',
  icon: 'icon-organization',
  submenu: [
    {
      text: 'País',
      link: '/catalog/country',
      auth: 'CATPAI',
    },
    {
      text: 'Organización',
      link: '/catalog/org',
      auth: 'CATORG',
    },
    {
      text: 'Región',
      link: '/catalog/regions',
      auth: 'CATREG',
    },
    {
      text: 'Gerencia Logística',
      link: '/catalog/management',
      auth: 'CATGER',
    },
    {
      text: 'Planta',
      link: '/catalog/factory',
      auth: 'CATFAB',
    },
    {
      text: 'Ceve',
      link: '/catalog/ceve',
      auth: 'CATCEV',
    },
    {
      text: 'Canal',
      link: '/catalog/channel',
      auth: 'CATCAN',
    },
    {
      text: 'Cliente',
      link: '/catalog/customer',
      auth: 'CATCLI',
    },
    {
      text: 'Clientes Terceros',
      link: '/management/responsible-list',
      auth: 'ADMTER',
    }
  ]
};


const Comercial = {
  text: 'Catálogo Comercial',
  link: '/commercial',
  icon: 'icon-pencil',
  submenu: [
    {
      text: 'Ruta - Vendedor',
      link: 'catalog/employee-route',
      auth: 'ROUTEMPLOY',
    },
    {
      text: 'Clientes - Cruce de Andén',
      link: 'catalog/cross-docking',
      auth: 'CROSSCUST',
    },
    {
      text: 'Subgerencia Despacho',
      link: '/commercial/dispatch-manager',
      auth: 'GERDES',
    },
    {
      text: 'Supervisor Despacho',
      link: '/commercial/dispatch-submanager',
      auth: 'SUBDES',
    },
    {
      text: 'Gerencia Autoservicio',
      link: '/commercial/self-service-manager',
      auth: 'GERAUT',
    },
    {
      text: 'División  Autoservicio',
      link: '/commercial/self-service-division',
      auth: 'DIVAUT',
    },
    {
      text: 'Proveedores',
      link: '/catalog/provider',
      auth: 'CATPROV',
    }
  ]

};


const Fiscal = {
  text: 'Catálogo Fiscal',
  link: '/tax',
  icon: 'icon-briefcase',
  submenu: [
    {
      text: 'Líneas de Transporte',
      link: '/tax/transportline',
      auth: 'TAXTLINE'
    },
    {
      text: 'Datos Fiscales',
      link: '/tax/fiscalconfig',
      auth: 'TAXCONFIG'
    },
    {
      text: 'Vehículos LS',
      link: '/tax/carrier',
      auth: 'TAXCARRIER'
    },
    {
      text: 'Configuración Vehícular  ',
      link: '/tax/vehicleconfig',
      auth: 'TAXVCONFIG'
    },
    {
      text: 'Código Postal',
      link: '/tax/zipcode',
      auth: 'TAXZIP'
    },
    {
      text: 'Monedas',
      link: '/tax/currency',
      auth: 'CATCUR'
    }
  ]
}

const EcoSystem = {
  text: 'Catálogo EcoSistema',
  link: '/ecosystem',
  icon: 'icon-globe',
  submenu: [
    {
      text: 'Sistema Gestión',
      link: '/catalog/manager-system',
      auth: 'CATMANSYS',
    },
    {
      text: 'Equipo',
      link: '/catalog/container',
      auth: 'CATCONT',
    },
    {
      text: 'Equipo en compulsa',
      link: '/catalog/adjust-container',
      auth: 'ADJCNTUNIT',
    },
    {
      text: 'Empleado',
      link: '/catalog/employee',
      auth: 'CATEMP',
    },
    {
      text: 'Tipo de Empleado',
      link: '/catalog/employee-type',
      auth: 'CATTEM',
    },
    {
      text: 'Razón de Incidencia',
      link: '/catalog/incidentReason',
      auth: 'CATINC',
    },
    {
      text: 'Estatus de Remisión',
      link: '/catalog/remission-status',
      auth: 'CATESREM',
    },
    {
      text: 'Producto',
      link: '/tax/product',
      auth: 'CATPROD'
    },
    {
      text: 'Artículo',
      link: '/catalog/item',
      auth: 'CATITEM'
    },
    {
      text: 'Datos Complementarios',
      link: '/catalog/complementary-data',
      auth: 'COMDAT',
    }
  ]
}

const Configuration = {
  text: 'Catálogo Configuración',
  link: '/configuration',
  icon: 'icon-settings',
  submenu: [
    {
      text: 'Equipo Controlado',
      link: '/management/controlled-container',
      auth: 'ADMEQU',
    },
    {
      text: 'Concentrador Ceves',
      link: '/catalog/concentrator',
      auth: 'CATCNC',
    },
    {
    text: 'Concentrador Plantas',
    link: '/catalog/factory-concentrator',
    auth: 'CATCNC',
    },
    {
      text: 'Calendario',
      link: '/configuration/calendar',
      auth: 'CALENDAR',
    },
    {
      text: 'Días Festivos',
      link: '/configuration/holliday',
      auth: 'HOLLIDAY',
    },
    {
      text: 'Rango de Vales',
      link: '/configuration/paybill-config',
      auth: 'PYBROUTE',
    }
    ,
    {
      text: 'Comodato',
      link: '/configuration/equipmentloan',
      auth: 'CONFLOAN',
    },
    {
      text: 'Lugares de Recuperación',
      link: '/catalog/place-recovery',
      auth: 'RECOPC'
    },
    {
      text: 'Configuración Sistema',
      link: '/configuration/settings',
      auth: 'CONSYS'
    }
  ]
};

const Binnacle = {
  text: 'Bitácora',
  link: '/reports',
  icon: 'icon-clock',
  submenu: [
    {
      text: 'Bitácora de Eventos',
      link: '/reports/events-log',
      auth: 'REPEVENT'
    },
    {
      text: 'Validación de Procesos',
      link: '/reports/ia-process',
      auth: 'VALPRO'
    },
    {
      text: 'Configuración Eventos',
      link: '/configuration/config-log',
      auth: 'LOGCONF'
    }
  ]
};

const headingMain = {
  text: '',
  heading: true,
  runGuardsAndResolvers: "always"
};

const Adjust = {
  text: 'Compulsas',
  link: '/inventory/adjust',
  icon: 'icon-layers',
  submenu: [
    {
      text: 'Compulsa Ceves',
      link: '/inventory/adjust/ceve',
      auth: 'ADJCEVE'
    },
    {
      text: 'Compulsa Plantas',
      link: '/inventory/adjust/factory',
      auth: 'ADJFACTORY'
    },
    {
      text: 'Vales',
      link: 'self-service/paybill',
      auth: 'PYBWEBC',
    },
  ]
};

export const menu = [
  headingMain,
  Home,
  Administration,
  Inventory,
  Entradas,
  Salidas,
  Adjust,
  Incidence,
  Autoservicios,
  Reports,
  Security,
  Catalog,
  Comercial,
  EcoSystem,
  Fiscal,
  Configuration,
  Binnacle

];
