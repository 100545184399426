// This file replaced environment.ts file during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// Uncomment for release
export const environment = {
  production: true,
    services_url: '/ceq/api/',
    service_version: 'v1/',
    apiKey: '9af16994-91e2-49f8-9228-1de48b67718c',
    firebase: {
      apiKey: "AIzaSyCEzJe31HRDjsyb85TrQ057Y9USjoQsYhk",
      authDomain: "ceq-prod.firebaseapp.com",
      databaseURL: 'https://ceq-prod-default-rtdb.firebaseio.com/',
      projectId: "ceq-prod",
      storageBucket: "ceq-prod.appspot.com",
      messagingSenderId: "390235701766",
      appId: "1:390235701766:web:115ff115b3aeeced983987",
      measurementId: "G-QVGBQB49TZ"
    }
};

//// Comment for release
//export const environment = {
//  production: false,
//  services_url: '/ceq/api/',
//  apiKey: '9af16994-91e2-49f8-9228-1de48b67718c',
//  service_version: 'v1/',
//  firebase: {
//    apiKey: "AIzaSyApPlFqr0yAjKF_k97bOSGncGuiX9TopbE",
//    authDomain: "ceq-dev-66f92.firebaseapp.com",
//    databaseURL: 'https://ceq-dev-66f92-default-rtdb.firebaseio.com/',
//    projectId: "ceq-dev-66f92",
//    storageBucket: "ceq-dev-66f92.appspot.com",
//    messagingSenderId: "195636457571",
//    appId: "1:195636457571:web:085abd20a24319e2c0c856",
//    measurementId: "G-L4SBENMXTJ"
//  }
//};
