import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from '../../common/services/GenericServices';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { IncidenceptService } from 'src/app/ceq/incidence/incidencept/incidencept.service';
import { Level } from '../../common/models/Level.Model';
import { Unit } from '../../common/models/Unit.Model';
import { Dict } from '../../common/models/Dict.Model';
import { User } from '../models/User.Model';


@Injectable({
  providedIn: 'root'
})
export class GlobalFunctionsService {
  constructor(
    private genericService: GenericService,
    private incptService: IncidenceptService,
    private router: Router,
  ) {
  }

  /**
   * Returns the only one ParentId object from a unitId given
   * USE: Detail catalog components
   * @param unitId
   */
  GetParentId(unitId: number): Observable<Unit> {
    let url = `unit/${unitId}?$select=ParentId`;
    return this.genericService.getRequest(url, {});
  }

  /**
   * Returns a Unit list with the level parent
   * USE: Catalogs
   * @param userId
   * @param level
   */
  GetParentList(userId: number, level: string): Observable<Unit[]> {
    let url = `userprofile/level/${userId}/${level}`;
    return this.genericService.getRequest(url, {}, 0);
  }

  /**
   * Returns the parent level from a node
   * USE: Catalogs
   * @param orgId
   * @param level
   */
  GetParentLevel(orgId: number, level: string): Observable<Level> {
    let url = `level/parent/${orgId}/${level}`;
    return this.genericService.getRequest(url, {}, 0);
  }

  /**
   * Returns a date string to set input date
   * @param minD
   * @param rest
   * @param minus
   */
  getStringDate(minD: Date, rest: Boolean, minus: number): string {
    var minS: string;
    var dayDate: number;
    var dayWeek: number;
    var monthDate: number;
    var dayS: string;
    var monthS: string;
    var onemore: number = 1;

    dayDate = minD.getDate();
    monthDate = minD.getMonth();
    dayWeek = minD.getDay();

    if (rest) {
      if (minus < 7 && dayWeek - minus <= 0) {
        minus = minus + 1;
      }

      if (dayDate - minus > 0) {
        dayDate = minD.getDate() - minus
      } else {
        onemore = 0;
        if (monthDate == 2) {
          dayDate = 28 + (minD.getDate() - minus);
        }
        if (monthDate == 4 || monthDate == 6 || monthDate == 9 || monthDate == 11) {
          dayDate = 30 + (minD.getDate() - minus);
        }
        if (monthDate == 1 || monthDate == 3 || monthDate == 5 || monthDate == 7 || monthDate == 8 || monthDate == 10 || monthDate == 0) {
          dayDate = 31 + (minD.getDate() - minus);
        }
      }
    }

    if (dayDate < 10) {
      dayS = '0' + dayDate.toString();
    } else {
      dayS = dayDate.toString();
    }

    if (monthDate + 1 < 10) {
      monthS = '0' + (monthDate + onemore).toString();
    } else {
      monthS = (monthDate + onemore).toString();
    }

    minS = minD.getFullYear() + '-' + monthS + '-' + dayS;
    return minS;
  }

  /**
   * Returns enviroment published
   * USE: Identify environment
   */
  getEnvironment(): Observable<Dict> {
    let url = `security/environment`;
    return this.genericService.getRequest(url, {});
  }
  /**
   * Returns Current CEQ Version
   * USE: Refresh Web Client.
   */
  getCeqVersion(): Observable<Dict> {
    let url = `security/version`;
    return this.genericService.getRequest(url, {});
  }

  //Methods
  isNullOrWhitespace(input: string) {
    if (typeof input === 'undefined' || input == null) return true;
    return input.replace(/\s/g, '').length < 1;
  }

  currencyFormatter(params) {
    var nf = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return nf.format(params);
  }
  
  decimalFormatter(params) {
    var nf = new Intl.NumberFormat('es-MX', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    return nf.format(params);
  }

  separatorFormatter(params) {
    var nf = new Intl.NumberFormat('es-MX', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return nf.format(params);
  }

  userLogin: User;

  /**
   * Se validan procesos que bloquean la operación
   * @returns 
   */
  canOperate(){
    // Se bloquea la operación si el nodo principal del usuario tiene autorizaciones pendientes
    this.userLogin = JSON.parse(localStorage.getItem("user"));
    if(this.userLogin.UnitId > 10000){
      let ceveid = this.userLogin.UnitId;
      this.incptService.hasDataComplementaryPending(ceveid).subscribe(resp => {
        if(resp > 0){
          Swal.fire({
            title: '<h2 style="color:beige;">Datos Complementarios Pendientes</h2>',
            html: '<label style="color:bisque;">Tiene que enviar a autorizar los Datos complementarios para seguir operando.</label>',
            confirmButtonText: 'Aceptar',
            background: '#000',
            allowOutsideClick: false,
            showClass: {
              popup: `animate__animated
                      animate__fadeInUp
                      animate__faster`
            },
            hideClass: {
              popup: `animate__animated
                      animate__fadeOutDown
                      animate__faster`
            }
          });
          this.router.navigate([`/incidence/inc-pt-ceve/0`]);
        }
      });

    }
  }

  /**Valida remisiones FSBR pendientes */
  hasFSBRPending() {
    this.userLogin = JSON.parse(localStorage.getItem("user"));
    if (this.userLogin.UnitId > 10000) {
      this.incptService.hasFSBRRemissionPending().subscribe(resp => {
        if (resp.Pending) {
          Swal.fire({
            title: '<h2 style="color:beige;">Rechazos Pendientes</h2>',
            html: `<label style="color:bisque;">${resp.Message}</label>`,
            confirmButtonText: 'Aceptar',
            background: '#000',
            allowOutsideClick: false,
            showClass: {
              popup: `animate__animated
                            animate__fadeInUp
                            animate__faster`
            },
            hideClass: {
              popup: `animate__animated
                            animate__fadeOutDown
                            animate__faster`
            }
          });
          this.router.navigate([`/management/remission-status`]);
        }

      });
    }
  }

  /**
   * Generate a toast message
   * @param msg: string to show in toast
   * @param icons: type of toast 
   * @param duration: time in ms
   */
  async messageToast(msg: string, icons: toastIcon, duration:number = 4000){
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: duration,
      timerProgressBar: true,
      icon: icons,
      html: '<label style="color: white">' + msg + '</label>',
      iconColor: 'white'
    });
    if(icons === 'question'){
      await Toast.fire({
        title: '<label style="color: white">DUDA</label>',
        background: 'DarkMagenta'
      });
    }
    if(icons === 'error'){
      await Toast.fire({
        title: '<label style="color: white">ERROR</label>',
        background: 'Crimson'
      });
    }
    if(icons === 'warning'){
      await Toast.fire({
        title: '<label style="color: white">CUIDADO</label>',
        background: 'DarkOrange'
      });
    }
    if(icons === 'info'){
      await Toast.fire({
        title: '<label style="color: white">AVISO</label>',
        background: 'SteelBlue'
      });
    }
    if(icons === 'success'){
      await Toast.fire({
        title: '<label style="color: white">CORRECTO</label>',
        background: 'MediumSeaGreen'
      });
    }
  }
}

export type toastIcon = 'question' | 'error' | 'warning' | 'info' | 'success';
